.nordworks_infocards {
    margin: 2rem;
    margin-top: 4rem;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
}

.nordworks_infocards-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--color-anthracite);
    box-shadow: inset 0 0 0.75rem;
    border-radius: 3rem;
    transition: 0.25s;
}

.nordworks_infocards-info:hover {
    box-shadow: inset 0 0 0.75rem var(--color-blue);
    transition: 0.25s;
}

.info-top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    margin-top: 1rem;
    width: 100%;
}

.info-bottom {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: 2rem;
    width: 100%;
}

.nordworks_infocards-info-icon {
    color: var(--color-yellow);
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin: 0rem 1.5rem;
}

.nordworks_infocards-info h1 {
    font-family: var(--font-family);
    color: var(--color-coldwhite);
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin: 0.5rem 1.5rem;
}

.nordworks_infocards-info-description {
    font-family: var(--font-family);
    font-size: 1rem;
    color: var(--color-yellow);
    margin: 0rem 0.8rem;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .nordworks_infocards-info-icon {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    .nordworks_infocards-info h1 {
        font-size: 1.3rem;
    }
    .nordworks_infocards-info-description {
        font-size: 0.95rem;
    }
}

@media screen and (max-width: 1024px) {
    .nordworks_infocards {
        margin-top: 2rem;
    }
    .nordworks_infocards-info-icon {
        font-size: 2rem;
        line-height: 2rem;
    }
    .nordworks_infocards-info h1 {
        font-size: 1.2rem;
    }
    .nordworks_infocards-info-description {
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 900px) {
    .nordworks_infocards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .nordworks_infocards-info-icon {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .nordworks_infocards-info h1 {
        font-size: 1rem;
    }
    .nordworks_infocards-info-description {
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 480px) {
    .nordworks_infocards {
        grid-template-columns: repeat(1, 1fr);
    }
    .nordworks_infocards-info-icon {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
    .nordworks_infocards-info h1 {
        font-size: 0.85rem;
    }
    .nordworks_infocards-info-description {
        font-size: 0.65rem;
    }
}