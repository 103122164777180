.ofertastolarz__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-radius: 3rem;
    box-shadow: inset 0px 0px 0.75rem var(--color-black);
    height: fit-content;
}

.ofertastolarz-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    height: 10rem;
}

.ofertastolarz-left img {
    border: 0;
    height: 100%;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    z-index: -1;
}

.ofertastolarz-center {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 2rem;
}

.ofertastolarz-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7rem;
    width: 50rem;
    margin-right: 1.5rem;
    border: 0;
    border-radius: 1.5rem;
    box-shadow: 0px 0px 0.75rem var(--color-black);
    background-color: var(--color-coldwhite);
}

.ofertastolarz-right_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem 1rem;
    width: fit-content;
    height: fit-content;
}

.ofertastolarz-right-icon_dolar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: var(--color-blue);
    
}

.ofertastolarz-right_zarobki {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0rem 1rem;
}

.ofertastolarz-right_zarobki h1 {
    display: flex;
    font-family: var(--font-family);
    font-size: 1.3rem;
    font-weight: 900;
    color: var(--color-blue);
    margin: 0;
}

.ofertastolarz-right_zarobki h2 {
    display: flex;
    font-family: var(--font-family);
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-blue);
    margin: 0;
}

.ofertastolarz-title {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.ofertastolarz-title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: var(--color-yellow);
    margin: 0;
}

.ofertastolarz-title h2 {
    display: flex;
    font-family: var(--font-family);
    font-size: 2rem;
    color: var(--color-yellow);
    margin: 0;
    margin-left: 1rem;
}

.ofertastolarz__panel1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 2rem 2rem;
    border: 0;
    border-radius: 3rem;
    box-shadow: inset 0px 0px 0.75rem var(--color-black);
    height: fit-content;
    background-color: var(--color-coldwhite);
}

.panel1_info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.panel1_info-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
    min-height: 3rem;
    background-color: var(--color-blue);
    border: 0;
    border-radius: 1rem;
}

.panel1_info-icon_ico {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: var(--color-coldwhite);
}

.panel1_info-description {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.panel1_info-description p {
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--color-black);
    font-size: 1.3rem;
}

.ofertastolarz__panel2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 2rem 2rem;
    border: 0;
    border-radius: 3rem;
    box-shadow: inset 0px 0px 0.75rem var(--color-black);
    height: fit-content;
    background-color: var(--color-coldwhite);
}

.panel2_info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
}

.panel2_info-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 25rem;
}

.panel2_info-left h1 {
    font-family: var(--font-family);
    font-weight: 900;
    color: var(--color-blue);
    font-size: 1.3rem;
}

.panel2_info-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 2rem;
    width: 100%;
}

.panel2_info-right h2 {
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--color-black);
    font-size: 1.3rem;
}

.ofertastolarz__panel3, .ofertastolarz__panel4, .ofertastolarz__panel5, .ofertastolarz__panel6 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 2rem 2rem;
    border: 0;
    border-radius: 3rem;
    box-shadow: inset 0px 0px 0.75rem var(--color-black);
    height: fit-content;
    background-color: var(--color-coldwhite);
}

.panel3_title, .panel4_title, .panel5_title, .panel6_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
}

.panel3_title h1, .panel4_title h1, .panel5_title h1, .panel6_title h1 {
    font-family: var(--font-family);
    font-weight: 900;
    color: var(--color-blue);
    font-size: 2rem;
}

.panel3_description, .panel4_description, .panel5_description, .panel6_description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.panel3_desc_info, .panel4_desc_info, .panel5_desc_info, .panel6_desc_info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}

.panel3_desc-icon, .panel4_desc-icon, .panel5_desc-icon, .panel6_desc-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    color: var(--color-blue);
    margin-right: 2rem;
}

.panel3_desc_info h2, .panel4_desc_info h2, .panel5_desc_info h2, .panel6_desc_info h2 {
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--color-black);
    font-size: 1.3rem;
}

@media screen and (max-width: 1200px) {
    .ofertastolarz-left {
        height: 8rem;
    }
    .ofertastolarz-right {
        height: 5rem;
    }
    .ofertastolarz-title-icon {
        font-size: 1.75rem;
    }
    .ofertastolarz-title h2 {
        font-size: 1.75rem;
    }
    .ofertastolarz-right-icon_dolar {
        font-size: 1.75rem;
    }
    .ofertastolarz-right_zarobki h1 {
        font-size: 1.2rem;
    }
    .ofertastolarz-right_zarobki h2 {
        font-size: 1.2rem;
    }
    .panel1_info-icon {
        min-width: 2.75rem;
        min-height: 2.75rem;
    }
    .panel1_info-icon_ico {
        font-size: 1.3rem;
    }
    .panel1_info-description p {
        font-size: 1.2rem;
    }
    .panel2_info-left h1 {
        font-size: 1.2rem;
    }
    .panel2_info-right h2 {
        font-size: 1.2rem;   
    }
    .panel3_title h1, .panel4_title h1, .panel5_title h1, .panel6_title h1 {
        font-size: 1.75rem;
    }
    .panel3_desc_info h2, .panel4_desc_info h2, .panel5_desc_info h2, .panel6_desc_info h2 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1024px) {
    .ofertastolarz__header {
        flex-direction: column;
        height: fit-content;
    }
    .ofertastolarz-left {
        width: 100%;
        height: 6rem;
    }
    .ofertastolarz-left img {
        object-fit: cover;
        object-position: 0 -11rem;
        width: 100%;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3rem;
        
    }
    .ofertastolarz-center {
        margin: 1rem 2rem;
        justify-content: center;
    }
    .ofertastolarz-title {
        justify-content: center;
    }
    .ofertastolarz-description {
        text-align: center;
        margin: 0rem 1rem;
    }
    .ofertastolarz-right {
        width: 100%;
        height: 3rem;
        margin-right: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 3rem;
        border-bottom-right-radius: 3rem;
        z-index: -1;
        padding: 2rem 0rem;
    }
    .panel2_info-left {
        width: 20rem;
    }
    .panel3_title, .panel4_title, .panel5_title, .panel6_title {
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 900px) {
    .ofertastolarz-left img {
        object-position: 0 -9.5rem;
    }
    .ofertastolarz__panel1 {
        grid-template-columns: repeat(3, 1fr);
    }
    .ofertastolarz-title-icon {
        font-size: 1.5rem;
    }
    .ofertastolarz-title h2 {
        font-size: 1.5rem;
    }
    .ofertastolarz-right-icon_dolar {
        font-size: 1.5rem;
    }
    .ofertastolarz-right_zarobki h1 {
        font-size: 1.1rem;
    }
    .ofertastolarz-right_zarobki h2 {
        font-size: 1.1rem;
    }
    .panel1_info-icon {
        min-width: 2.6rem;
        min-height: 2.6rem;
    }
    .panel1_info-icon_ico {
        font-size: 1.2rem;
    }
    .panel1_info-description p {
        font-size: 1.1rem;
    }
    .panel2_info-left h1 {
        font-size: 1.1rem;
    }
    .panel2_info-right h2 {
        font-size: 1.1rem;   
    }
    .panel3_title h1, .panel4_title h1, .panel5_title h1, .panel6_title h1 {
        font-size: 1.5rem;
    }
    .panel3_desc_info h2, .panel4_desc_info h2, .panel5_desc_info h2, .panel6_desc_info h2 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 768px) {
    .ofertastolarz-left img {
        object-position: 0 -7rem;
    }
    .ofertastolarz__panel1 {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    .ofertastolarz-title-icon {
        font-size: 1.4rem;
    }
    .ofertastolarz-title h2 {
        font-size: 1.4rem;
    }
    .ofertastolarz-right-icon_dolar {
        font-size: 1.4rem;
    }
    .ofertastolarz-right_zarobki h1 {
        font-size: 1rem;
    }
    .ofertastolarz-right_zarobki h2 {
        font-size: 1rem;
    }
    .panel1_info-icon {
        min-width: 2.5rem;
        min-height: 2.5rem;
    }
    .panel1_info-icon_ico {
        font-size: 1.1rem;
    }
    .panel1_info-description p {
        font-size: 1rem;
    }
    .panel2_info-left h1 {
        font-size: 1rem;
    }
    .panel2_info-right h2 {
        font-size: 1rem;   
    }
    .panel3_title h1, .panel4_title h1, .panel5_title h1, .panel6_title h1 {
        font-size: 1.4rem;
    }
    .panel3_desc_info h2, .panel4_desc_info h2, .panel5_desc_info h2, .panel6_desc_info h2 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .ofertastolarz-left img {
        object-position: 0 -5rem;
    }
    .ofertastolarz-title-icon {
        font-size: 1.2rem;
    }
    .ofertastolarz-title h2 {
        font-size: 1.2rem;
    }
    .ofertastolarz-right-icon_dolar {
        font-size: 1.3rem;
    }
    .ofertastolarz-right_zarobki h1 {
        font-size: 0.9rem;
    }
    .ofertastolarz-right_zarobki h2 {
        font-size: 0.9rem;
    }
    .panel1_info-icon {
        min-width: 2.5rem;
        min-height: 2.5rem;
    }
    .panel1_info-icon_ico {
        font-size: 1.1rem;
    }
    .panel1_info-description p {
        font-size: 0.9rem;
    }
    .panel2_info-left h1 {
        font-size: 0.9rem;
    }
    .panel2_info-right h2 {
        font-size: 0.9rem;   
    }
    .panel3_title h1, .panel4_title h1, .panel5_title h1, .panel6_title h1 {
        font-size: 1.3rem;
    }
    .panel3_desc_info h2, .panel4_desc_info h2, .panel5_desc_info h2, .panel6_desc_info h2 {
        font-size: 0.9rem;
    }
    .panel2_info-left {
        width: 15rem;
    }
    .panel3_title, .panel4_title, .panel5_title, .panel6_title {
        margin-bottom: 1.3rem;
    }
}

@media screen and (max-width: 480px) {
    .ofertastolarz-left img {
        object-position: 0 -4rem;
    }
    .ofertastolarz__panel1 {
        grid-template-columns: repeat(1, 1fr);
    }
    .ofertastolarz-title-icon {
        font-size: 1.2rem;
    }
    .ofertastolarz-title h2 {
        font-size: 1.2rem;
    }
    .ofertastolarz-right-icon_dolar {
        font-size: 1.3rem;
    }
    .ofertastolarz-right_zarobki h1 {
        font-size: 0.9rem;
    }
    .ofertastolarz-right_zarobki h2 {
        font-size: 0.9rem;
    }
    .panel1_info-icon {
        min-width: 2.5rem;
        min-height: 2.5rem;
    }
    .panel1_info-icon_ico {
        font-size: 1.1rem;
    }
    .panel1_info-description p {
        font-size: 0.85rem;
    }
    .panel2_info-left h1 {
        font-size: 0.85rem;
    }
    .panel2_info-right h2 {
        font-size: 0.85rem;   
    }
    .panel3_title h1, .panel4_title h1, .panel5_title h1, .panel6_title h1 {
        font-size: 1.2rem;
    }
    .panel3_desc_info h2, .panel4_desc_info h2, .panel5_desc_info h2, .panel6_desc_info h2 {
        font-size: 0.85rem;
    }
    .panel2_info {
        flex-direction: column;
    }
    .panel2_info-left {
        width: 100%;
    }
    .panel2_info-right {
        margin-top: 0.5rem;
        margin-left: 0;
    }
}