.nordworks__footer {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin-top: 2rem;
}

.nordworks__footer-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 17rem;
    background: var(--color-blue);
    padding-left: 2rem;
    padding-right: 2rem;
    grid-gap: 2rem;
}

.nordworks__footer-contactinfo {
    width: 100%;
    height: 13rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-left: 0.15rem solid var(--color-yellow);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.nordworks__footer-contactinfo h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.4rem;
    color: var(--color-coldwhite);
    margin-bottom: 0.75rem;
}

.nordworks__footer-contactinfo h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1rem;
    color: var(--color-coldwhite);
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
}

.nordworks__footer-contactinfo span {
    margin-right: 0.5rem;
}

.nordworks__footer-contactinfo p {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 0.9rem;
    color: var(--color-coldwhite);
}

.p-mail:hover {
    color: var(--color-yellow);
    text-decoration: underline;
}

.p-tel:hover {
    color: var(--color-yellow);
    text-decoration: underline;
}

.nordworks__footer-links {
    width: 100%;
    height: 13rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-left: 0.15rem solid var(--color-yellow);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.nordworks__footer-links h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.4rem;
    color: var(--color-coldwhite);
    margin-bottom: 0.75rem;
}

.nordworks__footer-links p {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 0.9rem;
    color: var(--color-coldwhite);
}

.nordworks__footer-links p:hover {
    color: var(--color-yellow);
    text-decoration: underline;
}

.nordworks__footer-copyright {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5rem;
    background: var(--color-anthracite);
    padding-left: 2rem;
    padding-right: 2rem;
    border-top: 0.15rem solid var(--color-yellow);;
}

.nordworks__footer-copyright-nordworks {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 14rem;
}

.nordworks__footer-copyright-nordworks img {
    width: 100%;
}

.nordworks__footer-copyright-nordworks button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    background: transparent;
    height: 1.5rem;
    cursor: pointer;
}

.nordworks__footer-copyright-nordworks button:hover {
    opacity: 0.7;
    transition: 0.25s;
}

.nordworks__footer-copyright-design {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nordworks__footer-copyright-design h2 {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-yellow);
    line-height: 1rem;
}

.nordworks__footer-copyright-sm {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    align-items: center;
    flex-direction: row;
    font-size: 1.5rem;
    color: var(--color-yellow);
    width: 10rem;
}

.nordworks__footer-copyright-sm_fb:hover {
    opacity: 70%;
    transition: 0.25s;
}

.nordworks__footer-copyright-sm_ig:hover {
    opacity: 70%;
    transition: 0.25s;
}

.nordworks__footer-copyright-sm_ln:hover {
    opacity: 70%;
    transition: 0.25s;
}

@media screen and (max-width: 1200px) {
    .nordworks__footer-container {
        height: 16.5rem;
    }
    .nordworks__footer-contactinfo {
        height: 12.5rem;
    }
    .nordworks__footer-contactinfo h1 {
        font-size: 1.3rem;
    }
    .nordworks__footer-contactinfo h3 {
        font-size: 0.95rem;
    }
    .nordworks__footer-contactinfo p {
        font-size: 0.85rem;
    }
    .nordworks__footer-links {
        height: 12.5rem;
    }
    .nordworks__footer-links h1 {
        font-size: 1.3rem;
    }
    .nordworks__footer-links p {
        font-size: 0.85rem;
    }
    .nordworks__footer-copyright-nordworks {
        width: 12rem;
    }
    .nordworks__footer-copyright-sm {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1024px) {
    .nordworks__footer-copyright {
        height: 4rem;
    }
    .nordworks__footer-copyright-nordworks {
        width: 11rem;
    }
    .nordworks__footer-copyright-sm {
        font-size: 1.2rem;
    }
    .nordworks__footer-copyright-design h2 {
        font-size: 0.95rem;
        line-height: 0.95rem;
    }
}

@media screen and (max-width: 900px) {
    .nordworks__footer-copyright {
        height: 3.5rem;
    }
    .nordworks__footer-copyright-nordworks {
        width: 10rem;
    }
    .nordworks__footer-copyright-sm {
        font-size: 1em;
    }
    .nordworks__footer-copyright-design h2 {
        font-size: 0.9rem;
        line-height: 0.9rem;
    }
}

@media screen and (max-width: 768px) {
    .nordworks__footer-container {
        height: 15rem;
    }
    .nordworks__footer-contactinfo {
        height: 11rem;
    }
    .nordworks__footer-contactinfo h1 {
        font-size: 1.1rem;
    }
    .nordworks__footer-contactinfo h3 {
        font-size: 0.85rem;
    }
    .nordworks__footer-contactinfo p {
        font-size: 0.75rem;
    }
    .nordworks__footer-links {
        height: 11rem;
    }
    .nordworks__footer-links h1 {
        font-size: 1.1rem;
    }
    .nordworks__footer-links p {
        font-size: 0.75rem;
    }
    .nordworks__footer-copyright-nordworks {
        width: 10rem;
    }
    .nordworks__footer-copyright-design h2 {
        font-size: 0.75rem;
        line-height: 0.75rem;
        text-align: center;
    }
    .nordworks__footer-copyright-sm {
        font-size: 1.1rem;
        gap: 1.5rem;
        width: 7rem;
    }
}

@media screen and (max-width: 600px) {
    .nordworks__footer-container {
        grid-template-columns: repeat(1, 1fr);
        height: fit-content;
        grid-gap: 1rem;
    }
    .nordworks__footer-contactinfo {
        height: 11rem;
        margin-bottom: 0.5rem;
    }
    .nordworks__footer-links {
        height: 6.5rem;
        margin-top: 0.5rem;
    }
    .nordworks__footer-copyright-nordworks {
        width: 7rem;
    }
    .nordworks__footer-copyright-design h2 {
        font-size: 0.65rem;
        line-height: 0.65rem;
        text-align: center;
    }
    .nordworks__footer-copyright-sm {
        font-size: 1.1rem;
        gap: 1em;
        width: 6rem;
    }
}
