.anchor-forms {
    display: block;
    position: absolute;
    top: -6rem;
    left: 0;
    visibility: hidden;
}

.nordworks_forms {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 4rem 2rem;
    border: 0;
    margin-left: 2rem;
    margin-right: 2rem;
}

.nordworks_forms-heading {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.nordworks_forms-heading h1 {
    font-family: var(--font-family);
    font-weight: 700;
    color: var(--color-blue);
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 2rem;
}

.nordworks_forms-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .nordworks_forms-heading h1 {
        font-size: 3rem;
        line-height: 3rem;
    }
}

@media screen and (max-width: 1024px) {
    .nordworks_forms {
        margin: 2rem 2rem;
    }
    .anchor-forms {
        top: -5rem;
    }
    .nordworks_forms-heading h1 {
        font-size: 2.7rem;
        line-height: 2.7rem;
    }
}

@media screen and (max-width: 900px) {

}

@media screen and (max-width: 768px) {
    .nordworks_forms-heading h1 {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }
}

@media screen and (max-width: 600px) {

}