.nordworks_about {
    position: relative;
    display: grid;
    height: fit-content;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    align-items: center;
    margin: 4rem 2rem;
}

.anchor-about {
    display: block;
    position: absolute;
    top: -6rem;
    left: 0;
    visibility: hidden;
}

.nordworks_about-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0.75rem;
    height: 100%;
    width: 100%;
    border: 0;
    border-radius: 3rem;
    padding: 1rem;
}

.nordworks_about-logo img {
    height: 100%;
    width: 100%;
}

.nordworks_about-panel {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.nordworks_about-panel h1 {
    font-family: var(--font-family);
    font-weight: 700;
    color: var(--color-blue);
    font-size: 4rem;
    line-height: 4rem;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.nordworks_about-panel p {
    font-family: var(--font-family);
    color: var(--color-white);
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
}

.nordworks_about-panel-description a {
    color: var(--color-yellow);
    text-decoration: underline;
}

@media screen and (max-width: 1200px) {
    .nordworks_about-panel h1 {
        font-size: 3rem;
        line-height: 3rem;
    }
    .nordworks_about-panel p {
        font-size: 0.95rem;
    }
}

@media screen and (max-width: 1024px) {
    .nordworks_about {
        margin: 2rem 2rem;
    }
    .anchor-about {
        top: -5rem;
    }
    .nordworks_about-panel h1 {
        font-size: 2.7rem;
        line-height: 2.7rem;
    }
    .nordworks_about-panel p {
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 900px) {
    .nordworks_about {
        grid-template-columns: repeat(1,1fr);
    }
    .nordworks_about-logo img {
        height: 75%;
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .nordworks_about-panel h1 {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }
    .nordworks_about-panel p {
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 480px) {
    .nordworks_about-panel h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
    .nordworks_about-panel p {
        font-size: 0.65rem;
    }
}