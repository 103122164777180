.anchor-offer {
    display: block;
    position: absolute;
    top: -6rem;
    left: 0;
    visibility: hidden;
}

.nordworks_offer {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 4rem 2rem;
}

.nordworks_offer-heading {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.nordworks_offer h1 {
    font-family: var(--font-family);
    font-weight: 700;
    color: var(--color-blue);
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 2rem;
    width: 100%;
    text-align: left;
}

.nordworks_offer-panel {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
}

.nordworks_offer-panel-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 10rem;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-radius: 3rem;
    box-shadow: 0px 0px 0.75rem var(--color-black);
}

.offer-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    height: 100%;
}

.offer-left img {
    border: 0;
    height: 100%;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    z-index: -1;
}

.offer-center {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0rem 2rem;
}

.offer-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 15rem;
    border: 0;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
}

.offer-right a {
    width: 100%;
    height: 100%;
}

.offer-right button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: var(--color-blue);
    color: var(--color-coldwhite);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.5rem;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.offer-right button:hover {
    background: rgba(29,93,155,0.7);
}

.offer-title {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 1rem;
}

.offer-title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: var(--color-yellow);
    margin: 0;
}

.offer-title h2 {
    display: flex;
    font-family: var(--font-family);
    font-size: 1.5rem;
    color: var(--color-yellow);
    margin: 0;
    margin-left: 1rem;
}

.offer-description {
    display: flex;
    text-align: left;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-white);
}

@media screen and (max-width: 1200px) {
    .nordworks_offer h1 {
        font-size: 3rem;
        line-height: 3rem;
    }
    .nordworks_offer-panel-container {
        height: 9rem;
    }
    .offer-right {
        width: 13rem;
    }
    .offer-right button {
        font-size: 1.3rem;
    }
    .offer-title-icon {
        font-size: 1.7rem;
    }
    .offer-title h2 {
        font-size: 1.3rem;
    }
    .offer-description {
        font-size: 0.95rem;
    }
}

@media screen and (max-width: 1024px) {
    .nordworks_offer {
        margin: 2rem 2rem;
    }
    .anchor-offer {
        top: -5rem;
    }
    .nordworks_offer h1 {
        font-size: 2.7rem;
        line-height: 2.7rem;
    }
    .nordworks_offer-panel-container {
        height: 8rem;
    }
    .offer-right {
        width: 12rem;
    }
    .offer-right button {
        font-size: 1.2rem;
    }
    .offer-title-icon {
        font-size: 1.5rem;
    }
    .offer-title h2 {
        font-size: 1.2rem;
    }
    .offer-description {
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 900px) {
    .nordworks_offer-panel-container {
        height: 7rem;
    }
    .offer-right {
        width: 12rem;
    }
}

@media screen and (max-width: 768px) {
    .nordworks_offer h1 {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }
    .nordworks_offer-panel-container {
        height: 5.5rem;
    }
    .offer-right {
        width: 10rem;
    }
    .offer-right button {
        font-size: 0.9rem;
    }
    .offer-title {
        margin-bottom: 0.5rem;
    }
    .offer-title-icon {
        font-size: 1.1rem;
    }
    .offer-title h2 {
        font-size: 0.9rem;
    }
    .offer-description {
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 600px) {
    .nordworks_offer-panel-container {
        flex-direction: column;
        height: fit-content;
    }
    .offer-left {
        width: 100%;
        height: 4rem;
    }
    .offer-left img {
        object-fit: cover;
        object-position: 0 -4rem;
        width: 100%;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3rem;
        
    }
    .offer-center {
        margin: 1rem 2rem;
        justify-content: center;
    }
    .offer-title {
        justify-content: center;
    }
    .offer-description {
        text-align: center;
        margin: 0rem 1rem;
    }
    .offer-right {
        width: 100%;
        height: 3rem;
    }
    .offer-right button {
        border-top-right-radius: 0;
        border-bottom-left-radius: 3rem;
    }
}

@media screen and (max-width: 480px) {
    .nordworks_offer h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
    .offer-description {
        font-size: 0.65rem;
    }
}