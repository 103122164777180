.nordworks__navbar {
    position: fixed;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-anthracite);
    border-bottom: 0.15rem solid var(--color-yellow);
    box-shadow: 0px 0.1rem 1rem;
    z-index: 3;
}

.nordworks__navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.nordworks__navbar-logo a {
    height: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nordworks__navbar-logo img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.nordworks__navbar-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
    height: 100%;
}

.nordworks__navbar-links p {
    position: relative;
    font-family: var(--font-family);
    font-size: 1.1rem;
    font-weight: 500;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-coldwhite);
    transition: 0.25s;
}

.nordworks__navbar-links p:hover {
    color: var(--color-yellow);
    transition: 0.25s;
}

.nordworks__navbar-links a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nordworks__navbar-links span {
    position: absolute;
    bottom: 0;
    height: 0rem;
    width: 100%;
    background-color: var(--color-yellow);
    display: block;
    margin: 0 auto;
    transition: 0.25s;
}

#home:hover > #line1 {
    height: 0.25rem;
    transition: 0.25s;
}

#about:hover > #line2 {
    height: 0.25rem;
    transition: 0.25s;
}

#offer:hover > #line3 {
    height: 0.25rem;
    transition: 0.25s;
}

#partners:hover > #line4 {
    height: 0.25rem;
    transition: 0.25s;
}

#contact:hover > #line5 {
    height: 0.25rem;
    transition: 0.25s;
}

.nordworks__navbar-socialmedia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2rem;
    gap: 1.5rem;
}

.nordworks__navbar-socialmedia a {
    font-size: 1.5rem;
    color: var(--color-yellow);
}

.nordworks__navbar-socialmedia a:hover {
    opacity: 70%;
    transition: 0.25s;
}

.nordworks__navbar-menubtn {
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.nordworks__navbar-menubtn_button {
    background: none;
    border: 0;
    margin-right: 2rem;
    cursor: pointer;
}

.burger, .burger::before, .burger::after {
    background: var(--color-yellow);
}

.nordworks__navbar-menu {
    position: fixed;
    top: 3rem;
    right: 0rem;
    height: fit-content;
    width: 100%;
    background: var(--color-blue);
    border-bottom: 0.15rem solid var(--color-yellow);
    box-shadow: 0px 1rem 1rem -1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0rem;
    z-index: 2;
}

.nordworks__navbar-menu p {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-coldwhite);
    width: fit-content;
}

.nordworks__navbar-menu p:hover {
    color: var(--color-yellow);
    transition: 0.25s;
}

.nordworks__navbar-menu-socialmedia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    gap: 1.3rem;
}

.nordworks__navbar-menu-socialmedia a {
    font-size: 1.3rem;
    color: var(--color-yellow);
}

.nordworks__navbar-menu-socialmedia a:hover {
    opacity: 70%;
    transition: 0.25s;
}


@media screen and (max-width: 1200px) {
    .nordworks__navbar-logo a {
        height: 3rem;
    }
    .nordworks__navbar-links {
        gap: 3rem;
    }
    .nordworks__navbar-socialmedia a {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1024px) {
    .nordworks__navbar {
        height: 3.5rem;
    }
    .nordworks__navbar-logo a {
        height: 2.7rem;
    }
    .nordworks__navbar-links {
        gap: 2rem;
    }
    .nordworks__navbar-links p {
        font-size: 1rem;
    }
    .nordworks__navbar-socialmedia a {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 900px) {
    .nordworks__navbar {
        height: 3rem;
    }
    .nordworks__navbar-logo a {
        height: 2.5rem;
    }
    .nordworks__navbar-links {
        gap: 1.5rem;
    }
    .nordworks__navbar-links p {
        font-size: 0.9rem;
    }
    .nordworks__navbar-socialmedia {
        gap: 1rem;
    }
    .nordworks__navbar-socialmedia a {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .nordworks__navbar-socialmedia {
        display: none;
    }
    .nordworks__navbar-links {
        display: none;
    }
    .nordworks__navbar-menubtn {
        display: flex;
    }

    #line1, #line2, #line3, #line4, #line5 {
        display: none;
    }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 480px) {
    .nordworks__navbar-logo a {
        height: 2rem;
    }
}