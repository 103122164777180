.anchor-home {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

.nordworks__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.nordworks__header-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nordworks__header-content img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1024px) {
    .nordworks__header {
        margin-top: 3.5rem;
    }
}

@media screen and (max-width: 900px) {
    .nordworks__header {
        margin-top: 3rem;
    }
}