form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;
    padding: 2rem 2rem;
    border: 0;
    border-radius: 3rem;
    box-shadow: inset 0 0 0.75rem;
}

.MuiFormHelperText-root {
    position: absolute;
    bottom: -1.5rem;
}

textarea {
    cursor: auto;
}

@media screen and (max-width: 768px) {
    form {
        gap: 1.1rem;
    }
    .MuiFormHelperText-root {
        bottom: -0.95rem;
    }
}