.anchor-partners {
    display: block;
    position: absolute;
    top: -6rem;
    left: 0;
    visibility: hidden;
}

.nordworks_partnership {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    border: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    height: fit-content;
}

.nordworks_partnership-heading {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.nordworks_partnership-heading h1 {
    font-family: var(--font-family);
    font-weight: 700;
    color: var(--color-blue);
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 2rem;
}

.nordworks_partnership-content {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 10rem;
    align-items: center;
}

.nordworks_partnership-content img {
    width: 100%;
}

.nordworks_partnership-content img:hover {
    scale: 1.1;
    transition: 0.25s;
}

@media screen and (max-width: 1200px) {
    .nordworks_partnership-heading h1 {
        font-size: 3rem;
        line-height: 3rem;
    }
    .nordworks_partnership-content {
        height: 8rem;
        grid-gap: 2rem;
    }
}

@media screen and (max-width: 1024px) {
    .nordworks_partnership {
        margin: 2rem 2rem;
    }
    .anchor-partners {
        top: -5rem;
    }
    .nordworks_partnership-heading h1 {
        font-size: 2.7rem;
        line-height: 2.7rem;
    }
    .nordworks_partnership-content {
        height: 7rem;
    }
}

@media screen and (max-width: 900px) {
    .nordworks_partnership-content {
        height: 6rem;
    }
}

@media screen and (max-width: 768px) {
    .nordworks_partnership-heading h1 {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }
    .nordworks_partnership-content {
        height: 5rem;
    }
}

@media screen and (max-width: 600px) {
    .nordworks_partnership-content {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 4rem;
    }
    .nordworks_partnership-content {
        height: fit-content;
    }
}